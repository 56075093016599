import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    status: "pending",
    data: {},
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        resetState: () => {
            return initialState
        }
    }
})


// Action creators are generated for each case reducer function
export const { resetState } = profileSlice.actions

export default profileSlice.reducer