import React, { useState } from "react";

import OtpInput from "react-otp-input";

import AccountLayout from "../../Components/Layouts/AccountLayout";
import { Button, InputOTP, SubTitle } from "../../Components/CustomComponents";
import { ACCOUNT_ENUM } from "./Route";

export default function OTP({ setPage }) {
  const [otp, setOtp] = useState("");

  const handleBackButtonClick = () => {
    setPage(ACCOUNT_ENUM.FORGOT_PASSWORD);
  };

  const handleSubmit = () => {
    setPage(ACCOUNT_ENUM.SET_PASSWORD);
  };

  return (
    <AccountLayout
      hasBackButton={false}
      handleBackButton={handleBackButtonClick}
    >
      <div className="d-flex justify-content-center w-75">
        <img
          src="/images/webapp_qr_logo.png"
          alt="Logo Assets"
          style={{ width: "100%" }}
        />
      </div>
      <br></br>

      <SubTitle
        text="OTP Verification"
        classes="black-text fs-5 fw-bold mb-2"
      />

      <p className="text-center">
        Please enter 6-digit code we have sent you on your email
      </p>

      <OtpInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        renderSeparator={<span> </span>}
        renderInput={(props) => <InputOTP props={props} />}
      />

      <div
        className="d-flex justify-content-center align-items-center rounded-4 ps-3 pe-3 pt-3 pb-3 my-5"
        style={{ background: "#EFEFEF" }}
      >
        <div className="fs-10" style={{ color: "#404040" }}>
          Resend in 00:48
        </div>
      </div>

      <Button
        text="Continue"
        classes="mt-3 mb-2 primary-btn"
        onClick={handleSubmit}
      />
    </AccountLayout>
  );
}
