import React, { useState } from "react";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import {
  Button,
  Checkbox,
  Description,
  Input,
  SubTitle,
} from "../../Components/CustomComponents";
import { ACCOUNT_ENUM } from "./Route";
import { useDispatch } from "react-redux";
import { updateLogin } from "../../Features/DefaultReducer";
import InternationalPhoneField from "../../Components/CustomComponents/InternationalPhoneField";




export default function Register({ setPage }) {
  const [phone, setPhone] = useState('');

  const dispatch = useDispatch();

  const handleLoginClick = () => setPage(ACCOUNT_ENUM.LOGIN);
  const handleSubmit = () => dispatch(updateLogin(true));

  return (
    <AccountLayout registeredPage={false}>
      <div className="d-flex justify-content-center w-75">
        <img
          src="/images/webapp_qr_logo.png"
          alt="Logo Assets"
          style={{ width: "100%" }}
        />
      </div>
      <br></br>
      <SubTitle text="Register Now" classes="black-text fs-5 fw-bold mb-2" />
      <Input placeholder="First Name" />
      <Input placeholder="Last Name" />
      <Input placeholder="Email Address" />

      <InternationalPhoneField phone={phone} setPhone={setPhone} />


      <Input classes="mt-2 mb-2" placeholder="Password" />
      <Input classes="mt-2 mb-2" placeholder="Confirm Password" />
      <Checkbox
        label="Agree to Web App QR Terms and Conditions & Privacy Policy"
        classes="mt-2 mb-2"
      />
      <Button
        onClick={handleSubmit}
        text="Register"
        classes="mt-3 mb-2 primary-btn"
      />
      <div className="d-flex justify-content-center flex-direction-row">
        <Description text="Already a member?" classes="fs-7" />
        <Description
          text="Login"
          classes="fs-7 ps-1 fw-bold link-text"
          onClick={handleLoginClick}
        />
      </div>
    </AccountLayout>
  );
}
