import React from 'react';
import styles from '../styles/uploadProgressBar.module.scss';

const UploadProgressBar = ({ data }) => {

    const progressPercentage = data.percent + '%';

    return (
        <div className={styles.layout}>


            <div className='d-flex flex-row gap-2 w-100'>

                <img alt='' src={data.image} width="50" style={{ borderRadius: 16 }} />


                <div className='d-flex flex-column w-100 gap-1'>
                    <div className='d-flex flex-row align-items-baseline justify-content-between '>

                        <p className='m-0'>{data.name}</p>


                        {
                            data.status === "uploading"
                                ?
                                <img alt='' src='images/icons/close_icon.svg' width="16" />
                                :
                                <img alt='' src='images/icons/delete_icon2.svg' width="16" />
                        }

                    </div>

                    <div className='d-flex flex-row align-items-center'>

                        <p className={`m-0 fs-8 me-1 ${styles.greyText}`}>
                            60 KB of 12O KB •
                        </p>

                        {
                            data.status === "uploading"
                                ?
                                <img alt='' src='images/icons/loading_icon.svg' width="16" />
                                :
                                <img alt='' src='images/icons/done_icon.svg' width="16" />
                        }

                        <p className={`m-0 fs-8 ms-1 ${styles.greyText}`}>
                            {data.status === "uploading" ? "Uploading..." : "Completed"}</p>

                    </div>
                </div>



            </div>

            {
                data.status === "uploading" ?
                    <div className={styles.loadingBar}>

                        <div className={styles.progress} style={{ width: progressPercentage }}>
                        </div>

                    </div>
                    : ""
            }

        </div>
    )
}

export default UploadProgressBar
