import { configureStore } from '@reduxjs/toolkit';


import defaultReducer from './Features/DefaultReducer';
import ProfileReducer from './Features/ProfileReducer';

export const store = configureStore({
    reducer: {
        default: defaultReducer,
        profile: ProfileReducer,
    },
})