import React from 'react'
import '../styles/uploadFiles.scss';
import { message, Upload } from 'antd';
import { Toast } from 'react-bootstrap';
const { Dragger } = Upload;

const UploadFiles = () => {

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJpgOrPng) {
            Toast('You can only upload JPG/PNG file!', 'error', false);
        }
        else if (!isLt2M) {
            Toast('Image must smaller than 2MB!', 'error', false);
        }
        return isJpgOrPng && isLt2M;
    };

    // const handleFileChange = (info) => {

    //     console.log(info, 'file input here!')


    //     setMediaImage(info.file.originFileObj);
    // }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const props = {
        name: 'file',
        multiple: true,
        showUploadList: false,
        disabled: true,
        accept: "image/jpeg,image/png,image/png",
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        beforeUpload: beforeUpload, // assuming beforeUpload is a function defined elsewhere
        // onChange: handleFileChange, // assuming handleFileChange is a function defined elsewhere
        customRequest: dummyRequest, // assuming dummyRequest is a function defined elsewhere

        onChange(info) {
            const { status } = info.file;
            console.log(status);

            if (status === 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };



    return (
        <div className='p-3'>
            <div className='d-flex flex-row align-items-start'>
                <img alt='' src='/images/icons/cloud_icon.svg' width="40px" />
                <div className='d-flex flex-column ms-3'>
                    <h5 className='m-0 fw-bold'>Upload files</h5>
                    <p className='m-0 gray-text'>Select and upload the files of your choice</p>
                </div>
            </div>


            <Dragger {...props} >

                <img alt='' src='images/icons/cloud_icon_black.svg' className='mt-3 mb-1' width="30px" />


                <p className="ant-upload-text fw-bold m-0">Choose a file or drag & drop it here</p>
                <p className="ant-upload-hint">
                    JPEG, PNG Format
                </p>

                <div className={`black-btn btn fw-light px-3 py-2 rounded-4 align-items-center d-flex justify-content-center m-auto my-4`} style={{ maxWidth: '170px' }} >
                    <img src="images/icons/browse_icon.svg" className="mx-2" alt="" />
                    Browse File
                </div>
            </Dragger>

        </div>
    )
}

export default UploadFiles
