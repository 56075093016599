import React, { useState } from 'react'
import styles from './styles/ImageSelectionCards.module.scss';
import ImageCard from './ImageCard';

const ImageSelectionCards = ({ imageList, setSelectedImageNumber }) => {

    const [selectedItems, setSelectedItems] = useState([]);


    const handleSelectImages = (index) => {


        if (selectedItems.includes(index)) {
            setSelectedItems(prv => {
                return prv.filter(i => i !== index);
            });

            setSelectedImageNumber(prv => prv - 1);

        } else {
            setSelectedItems(prv => {
                return [...prv, index];
            });

            setSelectedImageNumber(prv => prv + 1);
        }
    };


    return (
        <section className={styles.layout}>
            {
                imageList.map((image, index) => (


                    <ImageCard
                        data={index}
                        image={image}
                        isSelected={selectedItems.includes(index)}
                        onSelect={handleSelectImages}
                    />
                ))
            }
        </section>
    )
}

export default ImageSelectionCards
