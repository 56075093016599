import React from "react";

import styles from "./styles/AccountLayoutSecond.module.scss";

import { Container, Row, Col } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";

import { ShadowContainer } from "../CustomComponents";

export default function AccountLayoutSecond({
  hasBackButton = false,
  handleBackButton = () => {},
  children,
  registeredPage,
}) {
  return (
    <div className={styles.AccountLayoutSecond}>
      <div className={styles.sectionContainer}>
      <img
                src="/images/top_right_circle_reset.png"
                alt="circle"
                className={styles.top_right_image}
              />

            <img
                src="/images/bottom-circle_reset.png"
                alt="circle"
                className={styles.bottom_left_image}
              />

        <Row className={styles.row}>
          
          <Col md={ 12} lg={12} className={styles.formContainer}>
            <div
              className={styles.form}
              style={{ maxWidth: registeredPage ? "" : "420px" }}
            >
              {hasBackButton ? (
                <div
                  className={styles.backButtonContainer}
                  onClick={handleBackButton}
                >
                  <IoIosArrowBack />
                </div>
              ) : (
                ""
              )}
              {children}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
