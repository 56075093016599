import React, { useEffect, useState } from "react";
import DesktopLayout from "./DesktopLayout";
import { BREAKPOINTS } from "../../constants";
import MobileLayout from "./MobileLayout";


export default function Messages() {

    const [user, setUser] = useState('')
    const [isMobile, setIsMobile] = useState(window.innerWidth <= BREAKPOINTS.MOBILE);


    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);

        return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, [])


    function handleWindowSizeChange() {
        setIsMobile(window.innerWidth <= BREAKPOINTS.MOBILE)
    }


    return (
        isMobile ? <MobileLayout user={user} setUser={setUser} /> :
            <DesktopLayout setUser={setUser} />
    )
}