import React, { useEffect, useState } from "react";

import styles from "./styles/Navbar.module.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import { CiSearch } from "react-icons/ci";
import { Button, Description, SubTitle } from "../CustomComponents";
import { FaArrowLeft } from "react-icons/fa";
import { Navigate, useNavigate } from "react-router-dom";
import { BREAKPOINTS } from "../../constants";
import { IoHome } from "react-icons/io5";



export default function Navbar({ isSearchBar = true, isHomeButton = false, handleToggle, title = '', description = '' }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= BREAKPOINTS.MOBILE);
    const navigate = useNavigate();


    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);

        return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, [])


    function handleWindowSizeChange() {
        setIsMobile(window.innerWidth <= BREAKPOINTS.MOBILE)
    }

    const gotoHomeScreen = () => {
        // alert('Home Screen');
        navigate('/');

    }

    return (
        <div className={styles.Navbar}>
            <div className={styles.titleContainer}>
                {

                    // <span onClick={handleToggle} className={styles.toggleButton}><GiHamburgerMenu /></span>
                }


                <div className="d-flex flex-column">
                    <SubTitle classes={`${styles.title} black-text ms-2`} text={title} />

                    <Description classes={` black-text ms-2 `} text={description} />
                </div>

                {isSearchBar ?
                    <div className={styles.search}>
                        <div className={styles.searchButton}>
                            <CiSearch color="#212121" />
                        </div>
                        <input
                            type="text"
                            placeholder="Search Folders"
                            className="bigeroverFont p-2 ms-1"
                        // value={search}
                        // onChange={(e) => dispatch(setValue(e.target.value))}
                        />
                    </div>
                    : ''}
            </div>

            {isHomeButton ?
                <div className={`${styles.homeBtn} pe-3 fs-5 ${isMobile ? 'py-3' : 'py-2'} `}
                    onClick={gotoHomeScreen}>
                    <FaArrowLeft size={18} className="mx-2" />
                    {isMobile ? <IoHome /> :
                        'Home'
                    }

                </div>
                : ''}
        </div>
    )
}