import React from "react";

import styles from "./styles/Inbox.module.scss"
import { CardTitle } from "../../Components/CustomComponents";

import { FiSend } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { Form, InputGroup } from "react-bootstrap";
import { HiOutlineEmojiHappy } from "react-icons/hi";


export default function Inbox({ isMobile = false, setUser }) {

    const handleBackButtonClick = () => {
        setUser('');
    }

    return (
        <div className={styles.Inbox}>
            <div className={styles.titleContainer}>
                <div className={styles.userContainer}>
                    <div className={styles.imageContainer}>
                        {isMobile ?
                            <span
                                className={styles.backButton}
                                onClick={handleBackButtonClick}
                            >
                                <IoIosArrowBack />
                            </span> : ''}

                        <img
                            className={styles.image}
                            src={'/images/icons/profile_image.png'}
                            alt="User Profile"
                        />
                        <span className={styles.online}></span>

                    </div>
                    <div className={`${styles.nameContainer}`}>
                        <CardTitle
                            text={'Frank Cage'}
                            classes="lc-1 black-text"
                        />
                        <p className={styles.online}>Online</p>

                    </div>

                </div>

            </div>
            <div className={styles.chatContainer}>
                {[1, 1, 1, 1, 1, 1, 1].map((item, index) => {

                    const isMe = index % 2;
                    return (
                        <div
                            className={`${styles.chat} ${isMe ? styles.me : ''}`}
                        >
                            <span className={styles.info}>16:00</span>
                            <p
                                className={`${styles.message} ${isMe ? styles.me : ''}`}
                            >
                                Hello, good afternoon!  John Hello
                            </p>
                            <span className={styles.info}>Seen</span>
                        </div>
                    )
                })}

            </div>

            <div className={styles.messageContainer}>
                <InputGroup >

                    <Form.Control

                        size='lg'
                        className={` mt-1 mb-2 ${styles.messageBar}`}
                        placeholder='Type a message...'
                        type='text'
                    // onChange={onChange}
                    />
                    <div className="input-before-icon">
                        <HiOutlineEmojiHappy />
                    </div>

                    <div className={styles.inputAfterIcon}>

                        <button
                            className={`${styles.sendButton}`}
                        >
                            <FiSend />
                        </button>
                    </div>


                </InputGroup>


            </div>

        </div>
    )
}