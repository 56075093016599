import React from "react";

import styles from "./styles/AccountLayout.module.scss";

import { Container, Row, Col } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";

import { ShadowContainer } from "../CustomComponents";

export default function AccountLayout({
  hasBackButton = false,
  handleBackButton = () => {},
  children,
  registeredPage,
}) {
  return (
    <div className={styles.AccountLayout}>
      <div className={styles.sectionContainer}>
        <Row className={styles.row}>
          <Col
            md={ 12}
            lg={6}
            className={`${styles.mediaContainer}`}
          >
            <div className={styles.glass}>

              <p className="fs-3 m-4 text-white lh-sm">Create shared Photo Album and upload to Love ones</p>
              <img
                src="/images/girl_image.png"
                alt="girl_image"
                className={styles.girl_image}
              />
            </div>

            <img
                src="/images/orange_circle_login.png"
                alt="circle"
                className={styles.orange_circle}
              />

            <img
                src="/images/top_circle_login.png"
                alt="circle"
                className={styles.top_left_image}
              />

            <img
                src="/images/bottom_circle.png"
                alt="circle"
                className={styles.bottom_left_image}
              />

            {/* <img
              src="/images/login_bg.png"
              alt="background_image"
              className={styles.image}
            /> */}
          </Col>
          <Col md={ 12} lg={6} className={styles.formContainer}>
            <div
              className={styles.form}
              style={{ maxWidth: registeredPage ? "" : "420px" }}
            >
              {hasBackButton ? (
                <div
                  className={styles.backButtonContainer}
                  onClick={handleBackButton}
                >
                  <IoIosArrowBack />
                </div>
              ) : (
                ""
              )}
              {children}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
