export const WEB_ROUTES = {
    ACCOUNT: 'account',
    MY_ALBUMS: 'my-albums',
    SHARED_ALBUMS: 'shared-album',
    CHAT_ADMIN: 'chat-with-admin',
    ARCHIVE: 'archive',
    RECYCLE: 'recycle',
    SETTING: 'settings',
    PROFILE: 'profile',
    PASSWORD: 'password',
    HELP: 'help',
    UPLOADS_PHOTOS: 'upload-photos',
    OPEN_FOLDER: 'open-folder',
}

export const BREAKPOINTS = {
    MOBILE: 991
}
