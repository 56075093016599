import React from "react";
import styles from "./styles/index.module.css";
import {
  Button,
  Input,
} from "../../Components/CustomComponents";
import { Col, Form, Row } from "react-bootstrap";

// icon
import { IoCamera } from "react-icons/io5";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";


const PROFILE_SCREEN = ({ setPage }) => {
  const handleSubmit = () => {
    alert('Save Changes ...')
  }
  const handleUploadPhoto = () => {
    alert('Upload Photo ...')
  }

  return (
    <div className={styles.Profile}>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>

        </div>
      </div>

      <div className="d-flex flex-row align-items-center justify-content-between mb-4">

        <Breadcrumb parentFolder="Settings/" currentFolder="Account" />

      </div>



      <div className="d-flex flex-row align-items-center mb-4">
        <img src="images/icons/profile_image.png" alt="" className="me-1" width="100px" />
        <div className={`d-flex align-items-center px-3 ${styles.uploadBtn}`} onClick={handleUploadPhoto}>
          <IoCamera className="m-1" size={18} />
          Upload Photo
        </div>
      </div>


      <Row className={` mt-2 mb-3`}>
        <Col lg={6} md={6} sm={12}>
          <Form.Label class="m-o">First Name</Form.Label>
          <Input
            placeholder="Nolan"
            classes={`${styles.defaultPadding} mt-2 mb-3`}
            type='text'
          />
        </Col>
        <Col lg={6} md={6} sm={12}>

          <Form.Label class="m-o">Last Name</Form.Label>
          <Input
            placeholder="Carder"
            classes={`${styles.defaultPadding} mt-2 mb-3`}
            type='text'
          />
        </Col>
      </Row>

      <Row>
        <Col lg={6} md={6} sm={12}>



          <Form.Label class="m-o">Email</Form.Label>
          <Input
            placeholder="nolancarder@example.com"
            classes={`${styles.defaultPadding} mt-2 mb-3`}
            type='email'
          />


        </Col>
        <Col lg={6} md={6} sm={12}>


          <Form.Label class="m-o">Phone Number</Form.Label>
          <Input
            placeholder="Enter Number"
            classes={`${styles.defaultPadding} mt-2 mb-3`}
            type='number'
          />


        </Col>
      </Row>

      <Row className="d-flex align-items-end">
        <Col lg={6} md={6} sm={12}>


          <Form.Label class="m-o">Address</Form.Label>
          <Input
            placeholder="Enter Address"
            classes={`${styles.defaultPadding} mt-2 mb-3`}
            type='text'
          />

        </Col>
        <Col lg={6} md={6} sm={12}>

          <Button
            text='Save Changes'
            classes={`mt-3 mb-3 black-btn`}
            onClick={handleSubmit}
          />

        </Col>

      </Row>


    </div>
  );
};

export default PROFILE_SCREEN;
