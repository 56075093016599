import React from 'react';

import styles from "./styles/BorderedCard.module.scss"

export default function BorderedCard({ classes = '', children }) {
    return (
        <div className={`${styles.BorderedCard} ${classes}`}>
            {children}
        </div>
    )
}