import React, { useState } from "react";
import { ACCOUNT_ENUM } from "./Route";

import { useDispatch } from "react-redux";
import { updateLogin } from "../../Features/DefaultReducer";
import { InputGroup } from "react-bootstrap";

// Custom Component
import AccountLayout from "../../Components/Layouts/AccountLayout";
import {
  Button,
  Checkbox,
  Description,
  Input,
  SubTitle,

} from "../../Components/CustomComponents";

// Icons
import { CiUser } from "react-icons/ci";
import { PiKey } from "react-icons/pi";

export default function Login({ setPage }) {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleForgotPasswordClick = () => {
    setPage(ACCOUNT_ENUM.FORGOT_PASSWORD);
  };

  const handleSignUpClick = () => {
    setPage(ACCOUNT_ENUM.REGISTER);
  };

  const handleSubmit = () => {
    dispatch(updateLogin(true));
  };

  return (
    <AccountLayout>
      <div className="d-flex justify-content-center w-75">
        <img
          src="/images/webapp_qr_logo.png"
          alt="Logo Assets"
          style={{ width: "100%" }}
        />
      </div>

      <br></br>

      <SubTitle
        text="Login Credentials"
        classes="black-text fs-5 fw-bold mb-2"
      />

      <InputGroup>
        <Input
          placeholder="Email Address"
          type="email"
          value={email}
          onChange={handleEmail}
        />

        <div className="input-before-icon">
          <CiUser />
        </div>
      </InputGroup>

      <InputGroup>
        <Input
          classes="mt-2 mb-2"
          placeholder="Password"
          type="password"
          value={password}
          onChange={handlePassword}
        />
        <div className="input-before-icon">
          <PiKey />
        </div>
      </InputGroup>

      <div className="w-100 d-flex justify-content-between align-items-center">
        <Checkbox label="Keep me login" classes="mt-2 mb-2 " />
        <Description
          classes="ms-auto cursor-pointer"
          text="Forgot Password ?"
          onClick={handleForgotPasswordClick}
        />
      </div>

      <Button
        text="Login"
        classes="mt-3 mb-2 primary-btn"
        onClick={handleSubmit}
      />

      {/* <TextDivider text='Or continue with' classes="mt-4 mb-3" /> */}

      {
        // <Description text="Or continue with" classes="m-2 fs-8 text-center" />

        // <div className="d-flex justify-content-evenly flex-direction-row flex-wrap mt-4">
        //   <img
        //     src="/images/google_icon.png"
        //     alt="Facebook Icon"
        //     style={{ width: "15%", minWidth: '50px' }}
        //   />
        //   <img
        //     src="/images/apple_icon.png"
        //     alt="Facebook Icon"
        //     style={{ width: "15%", minWidth: '50px' }}
        //   />
        //   <img
        //     src="/images/facebook_icon.png"
        //     alt="Facebook Icon"
        //     style={{ width: "15%", minWidth: '50px' }}
        //   />
        // </div>

        // <div className="d-flex justify-content-center flex-direction-row mt-4">
        //   <Description text="Haven’t Account?" classes="fs-7" />
        //   <Description
        //     text="Create a new account!"
        //     classes=" fs-7 ps-1 fw-bold link-text "
        //     onClick={handleSignUpClick}
        //   />
        // </div>
      }
    </AccountLayout>
  );
}
