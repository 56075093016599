import React from "react";
import { Button, Input, SubTitle } from "../../Components/CustomComponents";
import { ACCOUNT_ENUM } from "./Route";
import AccountLayoutSecond from "../../Components/Layouts/AccountLayoutSecond";

export default function SetPassword({ setPage }) {

    const handleBackButtonClick = () => {
        setPage(ACCOUNT_ENUM.OTP)
    }

    const handleSubmit = () => {
        setPage(ACCOUNT_ENUM.LOGIN)
    }

    return (
        <AccountLayoutSecond
            hasBackButton={false}
            handleBackButton={handleBackButtonClick}
        >
            <div className="d-flex justify-content-center w-75">
                <img
                    src="/images/webapp_qr_logo.png"
                    alt="Logo Assets"
                    style={{ width: "100%" }}
                />
            </div>
            <br></br>
            <SubTitle text="Set New Password" classes="black-text fs-5 fw-bold mb-2" />




            <Input
                placeholder="New Password"
                classes="mt-2 mb-1"
                type='password'
            />



            <Input
                placeholder="Confirm  Password"
                classes="mt-2 mb-1"
                type='password'
            />



            <br></br>
            <br></br>

            <Button
                text='Save & Continue'
                classes="mt-3 mb-2 primary-btn"
                onClick={handleSubmit}
            />

        </AccountLayoutSecond>
    )
}