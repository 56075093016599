import React from 'react'
import styles from './styles/FolderCard.module.scss';
import SharingList from '../SharingList/SharingList';

const FolderCard = ({ folderName, handleOpenFolder }) => {
    return (
        <div className={styles.folderBG} role="button" onClick={() => { handleOpenFolder(folderName) }}>

            <img alt="" src="images/folder_shape.svg" className={styles.folderShape} />

            <div className={`${styles.firstRow} d-flex flex-row align-items-center justify-content-between w-100 px-lg-4 px-md-3 px-sm-4 px-3 py-2 py-lg-2 py-md-3 py-sm-4 fs-4`}>

                <div className={` w-50 d-flex justify-content-start`}>
                    <img alt="" src="images/icons/gallery_icon.svg" className="me-2" />
                    <p className="m-0 lc-1 fw-bold fs-5">{folderName}</p>
                </div>

                <div className={`w-50 d-flex justify-content-end`}>
                    <p className={`m-0 me-3 ms-3 fs-6 lc-1 ${styles.files}`}>1,734,430 Files</p>
                </div>

            </div>


            <div className={`${styles.secondRow} d-flex flex-row  justify-content-between w-100 px-lg-4 px-md-3 px-sm-4 px-3 py-2 py-lg-2 py-md-3 py-sm-4 fs-4`}>

                <div className={` w-50 d-flex flex-column justify-content-start  `}>
                    <p className={`m-0 me-2 fs-6 ${styles.greyText}`}>
                        Shared With:
                    </p>

                    <SharingList classes="mt-5" />
                </div>

                <div className={`w-50 d-flex justify-content-center`}>
                    <img alt="" src="images/gallery_image.png" width="80%" />
                </div>

            </div>


            <div className={`${styles.thirdRow} d-flex flex-row align-items-center justify-content-between w-100 px-lg-4 px-md-3 px-sm-4 px-3 py-2 py-sm-4 py-md-2 py-lg-2 fs-4`}>

                <div className={` w-50 d-flex justify-content-start`}>
                    <img alt="" src="images/icons/qr_icon_black.svg" className="me-2 " />
                    <p className="m-0 lc-1 fw-bold fs-5">View QR</p>
                </div>

                <div className={`w-50 d-flex justify-content-center `}>
                    <p className={`m-0 ms-auto me-3 me-md-4 me-sm-5 fs-6 lc-1 ${styles.files}`}>Open</p>
                </div>

            </div>



        </div>
    )
}

export default FolderCard
