import React from "react";
import styles from "./styles/index.module.css";
import { Button, Checkbox, Description, Input } from "../../Components/CustomComponents";
import { Col, Row } from "react-bootstrap";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import { ACCOUNT_ENUM } from "../Account/Route";
import { useNavigate } from "react-router-dom";
import { WEB_ROUTES } from "../../constants";
import { baseRoute } from "../../Helper";

const PASSWORD_SCREEN = ({ setPage }) => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    alert('password saved.')
  }

  // const handleForgotPasswordClick = () => {
  //   // setPage(ACCOUNT_ENUM.FORGOT_PASSWORD);
  //   navigate('/' + WEB_ROUTES.FORGOT_PASSWORD);
  //   // navigate(baseRoute(WEB_ROUTES.FORGOT_PASSWORD));
  // };

  return (
    <div className={styles.Dashboard}>


      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>

        </div>
      </div>

      <div className="d-flex flex-row align-items-center justify-content-between mb-4">
        <Breadcrumb parentFolder="Settings/" currentFolder="Password" />
      </div>

      <Row className={` mt-2 mb-2`}>
        <Col lg={4} md={6} sm={12}>

          <Input
            placeholder="Current Password"
            classes={`${styles.defaultPadding} mt-2 mb-1`}
            type='password'
          />

          {
            // <div className="w-100 d-flex justify-content-between align-items-center my-2">
            // <Description
            // classes="ms-auto cursor-pointer"
            // text="Forgot Password ?"
            // onClick={handleForgotPasswordClick}
            // />
            // </div>
          }

          <Input
            placeholder="New Password"
            classes={`${styles.defaultPadding} mt-2 mb-1`}
            type='password'
          />



          <Input
            placeholder="Confirm  Password"
            classes={`${styles.defaultPadding} mt-2 mb-1`}
            type='password'
          />


          <Button
            text='Save Password'
            classes={`mt-3 mb-2 black-btn`}
            onClick={handleSubmit}
          />
        </Col>
      </Row>
    </div>
  );
};

export default PASSWORD_SCREEN;
