import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"

/**Import Assets */
import styles from "./styles/ProtectedLayout.module.scss";
// import cssStyles from './styles/notification.module.css';


/**Import Custom Component */
import Navbar from "./Navbar";
import { WEB_ROUTES } from "../../constants";
import { baseRoute } from "../../Helper";
import { updateLogin } from "../../Features/DefaultReducer";

import { useDispatch } from "react-redux";
import FilterModal from "../Modals/FilterModal";
import NotificationModal from "../Modals/NotificationModal";
// Icons
import { IoDocumentText } from "react-icons/io5";
import { MdDashboard } from "react-icons/md";
import { TiMessages } from "react-icons/ti";
import { MdOutlinePayment } from "react-icons/md";
import { MdCurrencyExchange } from "react-icons/md";
import { TbLogout2 } from "react-icons/tb";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import { Widgets } from "@mui/icons-material";
import { FaAnglesLeft } from "react-icons/fa6";
import { FaAnglesRight } from "react-icons/fa6";
import { BiLogOut } from "react-icons/bi";

export default function ProtectedLayout({ title, description, isNavBar = false, isSearchBar, isHomeButton, selectedRoute, children }) {

    const [collapsed, setCollapsed] = useState(window.innerWidth > 991);
    const [notificationModal, setNotificationModal] = useState(false);
    const [filterModal, setFilterModal] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleResize = () => {
        if (window.innerWidth < 991) {
            handleMenuClose();
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    // todo: menu
    const topMenu = [
        {
            label: "My Albums",
            icon: '/images/icons/album_icon.png',
        },
        {
            label: "Shared Album",
            icon: '/images/icons/shared_icon.png',
        },
        {
            label: "Chat with admin",
            icon: '/images/icons/chat_icon.png',
        }
    ]

    const settingMenu = [
        {
            label: "Profile",
            icon: '/images/icons/account_icon.png',
        },
        {
            label: "Password",
            icon: '/images/icons/password_icon.png',
        },
        {
            label: "Help",
            icon: '/images/icons/help_icon.png',
        }
    ]

    const bottomMenu = [
        {
            label: "Archive",
            icon: '/images/icons/archive_icon.png',
        },
        {
            label: "Recycle",
            icon: '/images/icons/delete_icon.png',
        },
        {
            label: "Settings",
            icon: '/images/icons/setting_icon.png',
        }
    ]

    const handleToggle = () => {
        setCollapsed(!collapsed)
    }

    const handleMenuClose = () => {
        setCollapsed(false)
    }

    const handleViewProfile = () => {
        navigate(baseRoute(WEB_ROUTES.PROFILE))
        if (collapsed && window.innerWidth < 991) {
            handleMenuClose();
        }
    }

    const handleLogout = () => {
        dispatch(updateLogin(false))
    }


    return (
        <div className={styles.ProtectedLayout}>
            <div className={collapsed ? styles.background : ''} onClick={handleMenuClose}></div>




            <div
                className={`${styles.menu} ${collapsed ? `${styles.collapsed} visible` : styles.notCollapsed}`}
                id="sider"
            >


                <span onClick={handleToggle} className={`${styles.toggleButton2} `}>
                    {
                        collapsed ? <FaAnglesLeft /> : <FaAnglesRight />
                    }

                </span>


                <div className={`${styles.logo} ${!collapsed ? styles.notCollapsed : ''}`} to={`${process.env.REACT_APP_DOMAIN_DIR}/dashboard`}>
                    <img
                        src={'/images/icons/profile_image.png'}
                        alt="User Profile"
                        className={collapsed ? styles.imageCollapsed : styles.imageNotCollapsed}
                    />
                    {
                        collapsed
                            ?
                            <div className="d-flex flex-column">
                                <p className={`${styles.name} m-0`} >
                                    Welcome
                                </p>
                                <p className={`${styles.name} fw-bold m-0`} >
                                    Nolan Carder
                                </p>
                            </div>
                            : ""
                    }
                    {
                        //     <button
                        //     className={`${styles.profileButton} ${(selectedRoute === 5) ? 'bg' : ''}`}
                        //     onClick={handleViewProfile}
                        // >
                        //     View Profile
                        // </button>
                    }
                </div>

                {/* 
                <div className={`${styles.drawer} ${collapsed ? styles.collapsed : ''}`}>

                    <span onClick={handleToggle}>
                        <BiLogOut />
                    </span>
                </div> 
                */}

                {
                    selectedRoute > 4 ?
                        <div className={styles.secondTopMenu}>

                            {settingMenu.map(({ icon, label }, index) => {
                                return <Link to={`${process.env.REACT_APP_DOMAIN_DIR}/${label.toLowerCase().replace(/[^A-Z0-9]/ig, "-")}`}
                                    onClick={collapsed && window.innerWidth < 991 ? handleMenuClose : ''}
                                    className={`${styles.items} ${index + topMenu.length + bottomMenu.length === selectedRoute ? styles.activeItems : ""} ${collapsed ? '' : 'm-1 p-2'}`} key={index}>

                                    <img src={icon} alt="" className="mx-2" width={collapsed ? "16%" : "60%"} />

                                    {collapsed ? <p className="m-0">{label === 'Profile' ? 'Account' : label}</p> : ''}

                                </Link>
                            })}
                        </div>

                        :

                        topMenu.map(({ icon, label }, index) => {
                            return <Link to={`${process.env.REACT_APP_DOMAIN_DIR}/${label.toLowerCase().replace(/[^A-Z0-9]/ig, "-")}`}
                                onClick={collapsed && window.innerWidth < 991 ? handleMenuClose : ''}
                                className={`${styles.item} ${index === selectedRoute ? styles.active : ""} ${collapsed ? '' : ' p-2'} ${collapsed ? '' : styles.m14}`}

                                key={index}>

                                <img src={icon} alt="" width={collapsed ? "20%" : "80%"} style={{ margin: collapsed ? '' : 'auto', }} />

                                {collapsed ? <p>{label}</p> : ''}

                            </Link>
                        })

                }





                <div className={styles.bottomMenu}>
                    {bottomMenu.map(({ icon, label }, index) => {
                        return <Link to={`${process.env.REACT_APP_DOMAIN_DIR}/${label.toLowerCase().replace(/[^A-Z0-9]/ig, "-")}`}
                            onClick={collapsed && window.innerWidth < 991 ? handleMenuClose : ''}
                            className={`${styles.items} ${index + topMenu.length === selectedRoute ? styles.active : ""} ${collapsed ? '' : 'm-1 p-2'}`} key={index}>

                            <img src={icon} alt="" className="mx-2" width={collapsed ? "16%" : "60%"} />

                            {collapsed ? <p className="m-0">{label}</p> : ''}

                        </Link>
                    })}

                    <Link
                        className={`${styles.items} ${collapsed ? '' : 'm-1 p-2'}`}
                        onClick={handleLogout}>

                        <img src='/images/icons/logout_icon.png' alt="" className="mx-2" width={collapsed ? "16%" : "60%"} />

                        {collapsed ? <p className="m-0">Logout</p> : ''}
                    </Link>

                </div>

            </div>


            <div className={styles.routeContent}>
                {isNavBar
                    ?
                    <Navbar isSearchBar={isSearchBar}
                        isHomeButton={isHomeButton}
                        title={title}
                        description={description}
                        handleToggle={handleToggle} />
                    :
                    ""
                }
                <div className={styles.content}>

                    {children}
                </div>
            </div>
        </div >
    )


}
