import React from 'react'
import { FaCheckCircle } from 'react-icons/fa'

const ImageSelectionCounter = ({ selectedImageNumber }) => {
    return (
        <>
            {
                selectedImageNumber > 0 ?
                    <div className="d-flex align-items-center primary-text">

                        <FaCheckCircle className="mx-2" />

                        {selectedImageNumber} Photos Selected

                    </div>
                    : ''
            }
        </>
    )
}

export default ImageSelectionCounter
