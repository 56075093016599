import React from 'react'
import styles from './styles/ImageCard.module.scss';

const ImageCard = ({ data, image, onSelect, isSelected }) => {

    const handleSelection = () => {
        onSelect(data);
    }
    return (
        <div className={styles.imageContainer} onClick={(data) => handleSelection(data)} role="button">


            <img src={image} alt="" className={`${styles.image} ${isSelected ? styles.opacity : ''} `} />

            {isSelected
                ?
                <img src='images/icons/check_box_selected.svg' alt='' className={`${styles.checkBox}`} />
                :
                <img src='images/icons/check_box.svg' alt='' className={`${styles.checkBox}`} />
            }
        </div>
    );
}

export default ImageCard
