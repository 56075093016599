import React from "react";
import styles from "./styles/index.module.css";
import { Button, Input } from "../../Components/CustomComponents";
import { Col, Form, Row } from "react-bootstrap";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";

const HELP_SCREEN = () => {
  const handleSubmit = () => {
    alert('Send Message ...')
  }


  return (
    <div className={styles.Dashboard}>


      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>

        </div>
      </div>

      <div className="d-flex flex-row align-items-center justify-content-between mb-4">
        <Breadcrumb parentFolder="Settings/" currentFolder="Help" />
      </div>

      <Row className={` mt-2 mb-3`}>
        <Col lg={6} md={6} sm={12}>


          <Form.Label class="m-o">First Name</Form.Label>
          <Input
            placeholder="Nolan"
            classes={`${styles.defaultPadding} mt-2 mb-3`}
            type='text'
          />


          <Form.Label class="m-o">Last Name</Form.Label>
          <Input
            placeholder="Carder"
            classes={`${styles.defaultPadding} mt-2 mb-3`}
            type='text'
          />

        </Col>
        <Col lg={6} md={6} sm={12}>

          <Form.Label class="m-o">Email</Form.Label>
          <Input
            placeholder="nolancarder@example.com"
            classes={`${styles.defaultPadding} mt-2 mb-3`}
            type='email'
          />

          <Form.Label class="m-o">Phone Number</Form.Label>
          <Input
            placeholder="Enter Number"
            classes={`${styles.defaultPadding} mt-2 mb-3`}
            type='number'
          />

        </Col>

      </Row>
      <Row>
        <Col sm={12}>
          <Form.Label class="m-o">Message</Form.Label>
          <Input
            placeholder="Write your message.."
            classes={`${styles.defaultPadding} mt-2 mb-3`}
            type='textarea'
            as='textarea'
            rows={5}
          />

        </Col>
      </Row>
      <Row>

        <Col sm={12} md={{ order: 1, span: 6 }}>
          <Button
            text='Send Message'
            classes={`mt-3 mb-3 black-btn`}
            onClick={handleSubmit}
          />
        </Col>
        <Col></Col>
      </Row>

    </div>
  );
};

export default HELP_SCREEN;
