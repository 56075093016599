import React from 'react'
import { Input } from '../../../Components/CustomComponents'
import styles from '../styles/uploadFolder.module.scss';

const UploadFolder = () => {
    const handleGenerateQR = () => {
        alert('QR')
    }
    return (
        <div className='p-3'>
            <div className='d-flex flex-row align-items-start'>
                <img alt='' src='/images/icons/folder_icon_blue.svg' width="40px" />
                <div className='d-flex flex-column ms-3'>
                    <h5 className='m-0 fw-bold'>Folder Name</h5>
                    <p className='m-0 gray-text'>Select and upload the files of your choice</p>
                </div>
            </div>

            <Input
                placeholder="Please enter the folder name here"
                classes={`${styles.input} my-4`}
                type='text'
            />

            <div className={`black-btn btn w-100 fw-light p-3 rounded-4 m-0`} onClick={handleGenerateQR}>
                <img src="images/icons/qr_icon_btn.svg" className="mx-2" alt="" />
                Generate QR
            </div>





        </div>
    )
}

export default UploadFolder
