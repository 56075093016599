import React, { useState } from "react";
import styles from "./styles/index.module.scss";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import SharingList from "../../Components/SharingList/SharingList";
import ImageSelectionCards from "../../Components/Cards/ImageSelectionCards";
import ImageSelectionCounter from "../../Components/Counter/ImageSelectionCounter";

const RECYCLE_SCREEN = () => {
  const [selectedImageNumber, setSelectedImageNumber] = useState(0);

  const recycleImages = [
    'images/image_1.png',
    'images/image_2.png',
    'images/image_3.png',
    'images/image_4.png',
    'images/image_5.png',
    'images/image_6.png',
    'images/image_1.png',
    'images/image_2.png',
    'images/image_3.png',
    'images/image_4.png',
    'images/image_5.png',
    'images/image_6.png',
    'images/image_1.png',
    'images/image_2.png',
    'images/image_3.png',
    'images/image_4.png',
    'images/image_5.png',
    'images/image_6.png',
  ]

  const handleRestorePhotos = () => {
    alert('Restore Photos...');
  }

  const handleDeletePermanently = () => {
    alert('Delete Permanently...');
  }

  return (
    <div className={styles.Dashboard}>


      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>

        </div>
      </div>


      <div className="d-flex flex-column flex-md-row align-items-center justify-content-between mb-4">

        <Breadcrumb parentFolder="Folders/" currentFolder="Recycle bin" />

        <div className="d-flex flex-column flex-md-row">


          <ImageSelectionCounter selectedImageNumber={selectedImageNumber} />

          <div className={styles.lightBlueBtn} onClick={handleRestorePhotos}>
            <img src="images/icons/restore_icon.svg" className="me-2" alt="" />
            Restore Photos
          </div>
          <div className={`d-flex align-items-center ${styles.lightGreyBtn}`} onClick={handleDeletePermanently}>
            <img src="images/icons/delete_icon.svg" className="me-2" alt="" />
            Delete Permanently
          </div>
        </div>
      </div>

      <ImageSelectionCards imageList={recycleImages} setSelectedImageNumber={setSelectedImageNumber} />



    </div>
  );
};

export default RECYCLE_SCREEN;
