import React from 'react'
import './InternationalPhoneField.scss';

import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

const InternationalPhoneField = ({ phone , setPhone, defaultCountry = 'us', placeholder="+1 Phone Number"}) => {
  return (
    <><PhoneInput
    className="mt-1 mb-2 form-control-lg form-control d-flex"
    placeholder={placeholder}
      defaultCountry={defaultCountry}
      value={phone}
      onChange={(phone) => setPhone(phone)}
    /></>
  )
}

export default InternationalPhoneField