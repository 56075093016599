import React from 'react';

import styles from "./styles/ChatThreads.module.scss"

import { CardTitle } from '../../Components/CustomComponents';

import BorderedCard from '../../Components/Cards/BorderedCard';

export default function ChatThreads({ setUser }) {

    const handleThreadClick = () => {
        setUser('123');
    }

    return (
        <div className={styles.ChatThreads}>

            <div className={styles.threads}>
                {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((item, index) => {
                    return (
                        <div
                            onClick={handleThreadClick}
                        >
                            <BorderedCard classes='my-2 overflow-visible'>
                                <div
                                    className={styles.thread}
                                >
                                    <img
                                        className={styles.userImage}
                                        src={'/images/icons/profile_image.png'}
                                        alt='User Profile'
                                    />
                                    <div className={styles.userInfo}>
                                        <div className={styles.nameContainer}>
                                            <CardTitle
                                                text='Frank Cage'
                                                classes='fs-6 lc-1 black-text'
                                            />
                                            <span className={styles.indicator}></span>
                                        </div>
                                        <p className={`${styles.message} mt-1 pe-1 lc-1 black-text`}>
                                            Of course, the apartment is
                                        </p>
                                    </div>
                                </div>
                            </BorderedCard>
                        </div>
                    )
                })}

            </div>
        </div>
    )
}