import React from 'react'
import './breadcrumb.moduls.scss';
import { current } from '@reduxjs/toolkit';


const Breadcrumb = ({ parentFolder, currentFolder }) => {
    return (
        <div className="d-flex align-items-center">
            <img src="images/icons/folder_icon.svg" className="me-1" /> {parentFolder} <b className="fs-5"> {currentFolder} </b>
        </div>
    )
}

export default Breadcrumb
