import React, { useState } from "react";
import styles from "./styles/index.module.scss";
import { useNavigate } from "react-router-dom";
import { WEB_ROUTES } from '../../constants';
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import ImageSelectionCards from "../../Components/Cards/ImageSelectionCards";
import ImageSelectionCounter from "../../Components/Counter/ImageSelectionCounter";


const ARCHIVE_SCREEN = () => {

  const [selectedImageNumber, setSelectedImageNumber] = useState(0);


  const archiveImages = [
    'images/image_1.png',
    'images/image_2.png',
    'images/image_3.png',
    'images/image_4.png',
    'images/image_5.png',
    'images/image_6.png',
    'images/image_1.png',
    'images/image_2.png',
    'images/image_3.png',
    'images/image_4.png',
    'images/image_5.png',
    'images/image_6.png',
    'images/image_1.png',
    'images/image_2.png',
    'images/image_3.png',
    'images/image_4.png',
    'images/image_5.png',
    'images/image_6.png',
  ]

  const navigate = useNavigate();

  const handleRestorePhotos = () => {
    alert('Restore Photos...');
  }

  const gotoRecycleBin = () => {
    navigate('/' + WEB_ROUTES.RECYCLE);
  }


  return (
    <div className={styles.Dashboard}>


      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>

        </div>
      </div>

      <div className="d-flex flex-column flex-md-row align-items-center justify-content-between mb-4">

        <Breadcrumb parentFolder="Folder/" currentFolder="Archive" />

        <div className="d-flex flex-column flex-md-row">


          <ImageSelectionCounter selectedImageNumber={selectedImageNumber} />

          <div className={styles.lightBlueBtn} onClick={handleRestorePhotos}>
            <img src="images/icons/restore_icon.svg" className="me-2" alt="" />
            Restore Photos
          </div>
          <div className={styles.lightGreyBtn} onClick={gotoRecycleBin}>
            <img src="images/icons/delete_icon.svg" className="me-2" alt="" />
            Recycle bin
          </div>
        </div>
      </div>

      <ImageSelectionCards imageList={archiveImages} setSelectedImageNumber={setSelectedImageNumber} />

    </div>
  );
};

export default ARCHIVE_SCREEN;
