import React, { useState } from 'react';
import { ROUTES } from './Route';

import SHARED_ALBUM_SCREEN from '../SharedAlmubs/SharedAlbums';


export default function ARCHIVE() {
    const [page, setPage] = useState(ROUTES.ARCHIVE);

    switch (page) {
        case ROUTES.ARCHIVE:
            return <SHARED_ALBUM_SCREEN setPage={setPage} />

        default:
            return <SHARED_ALBUM_SCREEN setPage={setPage} />
    }


}

