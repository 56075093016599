import React from 'react';
import '../styles/uploadProgress.module.scss';
import { Upload } from 'antd';
import UploadProgressBar from './UploadProgressBar';

const UploadProgress = () => {
    const fileList = [
        {
            uid: '0',
            name: 'New.year2023 Jpeg',
            status: 'uploading',
            percent: 33,

            image: 'images/image_1.png',
        },
        {
            uid: '1',
            name: 'New.year2023 Jpeg',
            status: 'uploading',
            percent: 66,

            image: 'images/image_2.png',
        },
        {
            uid: '2',
            name: 'New.year2023 Jpeg',
            status: 'uploading',
            percent: 88,

            image: 'images/image_3.png',
        },
        {
            uid: '3',
            name: 'New.year2023 Jpeg',
            status: 'done',

            image: 'images/image_4.png',
        },
        {
            uid: '4',
            name: 'New.year2023 Jpeg',
            status: 'done',

            image: 'images/image_5.png',
        },
        {
            uid: '50',
            name: 'New.year2023 Jpeg',
            status: 'done',

            image: 'images/image_6.png',
        },
    ];

    return (
        <div className='p-3'>
            <h5 className='mb-3 fw-bold'>Upload Progress</h5>

            {
                fileList.map((data, index) =>
                (
                    <UploadProgressBar data={data} />
                ))
            }

        </div>
    )
}

export default UploadProgress
