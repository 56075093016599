import React, { useState } from "react";
import styles from "./styles/index.module.scss";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import { useLocation } from "react-router-dom";
import { WEB_ROUTES } from "../../constants";
import SharingList from "../../Components/SharingList/SharingList";
import ImageSelectionCards from "../../Components/Cards/ImageSelectionCards";
import ImageSelectionCounter from "../../Components/Counter/ImageSelectionCounter";
import ModalContainer from "../../Components/ModalContainer";
import { FaCircleXmark } from 'react-icons/fa6';

const OPEN_FOLDER_SCREEN = () => {

  const [selectedImageNumber, setSelectedImageNumber] = useState(0);
  const [viewQRModal, setViewQRModal] = useState(false);

  // const navigate = useNavigate();

  const { state } = useLocation(); // receive data from Navigated Screen 

  const folderImages = [
    'images/image_1.png',
    'images/image_2.png',
    'images/image_3.png',
    'images/image_4.png',
    'images/image_5.png',
    'images/image_6.png',
    'images/image_1.png',
    'images/image_2.png',
    'images/image_3.png',
    'images/image_4.png',
    'images/image_5.png',
    'images/image_6.png',
    'images/image_1.png',
    'images/image_2.png',
    'images/image_3.png',
    'images/image_4.png',
    'images/image_5.png',
    'images/image_6.png',
  ]

  const handleShowViewQR = () => setViewQRModal(true);
  const handleViewQRModalClose = () => setViewQRModal(false);


  const handleDeletePhoto = (folderName) => {
    alert('handleDeletePhoto');

  }

  return (
    <div className={styles.OpenFolder}>
      {
        viewQRModal ?
          <ModalContainer
            handleClose={handleViewQRModalClose}
          >

            <div className={styles.modalContainer}>
              <div className='d-flex flex-row w-100 justify-content-end'>
                <FaCircleXmark onClick={handleViewQRModalClose} style={{ cursor: 'pointer' }} size={25} />
              </div>
              <div className="w-75 d-flex flex-column align-items-center ">

                <p className="m-0 fs-2 fw-bold text-center">
                  Scan Your QR Code <br></br> Get Your Album
                </p>

                <p className="gray-text">
                  Lorem ipsum dolor sit amet consectetur.
                </p>

                <img alt="" src="images/QR_image.png" width="50%" className="py-2" />

                <div className={`${styles.scanHere} mb-4`}>
                  <p className="m-0 fs-5 text-white fw-light">
                    Scan Here
                  </p>
                  <img alt="" src="images/btn_shape.svg" width="100%" />
                </div>

                <p className="gray-text">
                  Lorem ipsum dolor sit amet consectetur.
                </p>

              </div>
            </div>

          </ModalContainer>

          : ''
      }

      <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between mb-4">

        <Breadcrumb parentFolder="Folders/ " currentFolder={state.folderName} />

        <div className="align-items-end d-flex flex-row flex-wrap me-lg-auto ms-lg-3 mt-4 mt-lg-0" style={{ width: '16em' }}>
          <p className={`m-0 me-2  ${styles.greyText}`}>
            Shared With:
          </p>

          <SharingList />
        </div>



        <div className="d-flex flex-column flex-md-row">

          <ImageSelectionCounter selectedImageNumber={selectedImageNumber} />


          <div className={`d-flex align-items-center ${styles.lightGreyBtn}`} onClick={handleDeletePhoto}>
            <img src="images/icons/delete_icon.svg" alt="" className="me-2" />
            Delete Photos
          </div>

          <div className={`d-flex align-items-center px-4 ${styles.blackBtn}`} onClick={handleShowViewQR}>
            <img src="images/icons/qr_icon_btn.svg" alt="" className="me-2" />
            View QR
          </div>
        </div>
      </div>


      <ImageSelectionCards imageList={folderImages} setSelectedImageNumber={setSelectedImageNumber} />




    </div>
  );
};

export default OPEN_FOLDER_SCREEN;
