import React, { useState } from "react";
import {
  Button,
  Input,
  SubTitle,
} from "../../Components/CustomComponents";
import { ACCOUNT_ENUM } from "./Route";
import AccountLayoutSecond from "../../Components/Layouts/AccountLayoutSecond";
import InternationalPhoneField from "../../Components/CustomComponents/InternationalPhoneField";
import { InputGroup } from "react-bootstrap";
import { CiUser } from "react-icons/ci";

export default function ForgotPassword({ setPage }) {
  // const [phone, setPhone] = useState('');
  const [email, setEmail] = useState("");

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleBackButtonClick = () => {
    setPage(ACCOUNT_ENUM.LOGIN);
  };

  const handleSubmit = () => {
    setPage(ACCOUNT_ENUM.OTP);
  };

  return (
    <AccountLayoutSecond
      hasBackButton={false}
      handleBackButton={handleBackButtonClick}
    >
      <div className="d-flex justify-content-center w-75">
        <img
          src="/images/webapp_qr_logo.png"
          alt="Logo Assets"
          style={{ width: "100%" }}
        />
      </div>
      <br></br>
      <SubTitle text="Forget Password" classes="black-text fs-5 fw-bold mb-2" />

      <p className="text-center">
        In order to reset your password you need to enter your registered email.
      </p>
      {
        // <InternationalPhoneField phone={phone} setPhone={setPhone} />
      }

      <InputGroup>
        <Input
          placeholder="Email Address"
          type="email"
          value={email}
          onChange={handleEmail}
        />

        <div className="input-before-icon">
          <CiUser />
        </div>
      </InputGroup>

      <Button
        text="Continue"
        classes="mt-3 mb-2 primary-btn"
        onClick={handleSubmit}
      />

      {/* <div className="d-flex justify-content-center flex-direction-row mt-5">
        <Description text="Haven’t Account?" classes="fs-7" />
        <Description
          text="Create a new account!"
          classes="fs-7 ps-1 fw-bold link-text"
          onClick={handleSignUpClick}
        />
      </div> */}
    </AccountLayoutSecond>
  );
}
