import React from "react";
import styles from "./styles/index.module.scss";
import UploadFiles from "./Components/UploadFiles";
import UploadProgress from "./Components/UploadProgress";
import UploadFolder from "./Components/UploadFolder";

const UPLOADS_PHOTOS_SCREEN = () => {


  return (
    <div className={styles.Dashboard}>

      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>

        </div>
      </div>

      <section className={styles.layout}>
        <div className={styles.container_upload_files} >
          <UploadFiles />
        </div>
        <div className={styles.container_upload_progress} >
          <UploadProgress />
        </div>
        <div className={styles.container_upload_folder} >
          <UploadFolder />
        </div>
      </section>

    </div>
  );
};

export default UPLOADS_PHOTOS_SCREEN;
