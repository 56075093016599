import { React, useRef } from "react";
import cssStyles from "./style.module.css";
import { useDraggable } from "react-use-draggable-scroll";

const SharingList = ({ classes }) => {
  const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
  const { events } = useDraggable(ref,
    {
      applyRubberBandEffect: true, // activate rubber band effect
      decayRate: 0.96, // specify the decay rate
      safeDisplacement: 11, // specify the drag sensitivity
    }
  ); // Now we pass the reference to the useDraggable hook:

  const peopleWhoShared = [
    "/images/share1.png",
    "/images/share2.png",
    "/images/share3.png",
    "/images/share1.png",
  ];

  return (

    <div
      // style={{ position: 'relative', display: "inline-flex", overflow: 'auto', width: '100%', height: '60px' }}
      // className="flex max-w-xl space-x-3 overflow-x-scroll scrollbar-hide"
      style={{ position: 'relative' }}
      className={`d-flex align-items-end ${classes} `}
      {...events}
      ref={ref} // add reference and events to the wrapping div
    >

      {
        peopleWhoShared.map((logo, index) => (
          index < 3 ?
            <div key={index} style={{ position: "absolute", left: 30 * index + 'px', zIndex: index }}>
              <img src={logo} alt={"Team Logo"} style={{}} className={cssStyles.team_logo} />
            </div>
            :
            <div key={index} style={{ position: "absolute", left: 30 * index + 'px', zIndex: index }}>
              <div className={`${cssStyles.team_logo} ${cssStyles.existingLogo}`} >
                +{peopleWhoShared.length - 3}
              </div>
            </div>
        ))
      }
    </div >
  );
};

export default SharingList;
