import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { baseRoute } from "../../Helper";
import { WEB_ROUTES } from "../../constants";


export default function PublicRoute({ children }) {

    const { isLoggedIn } = useSelector(state => state.default);


    return (
        isLoggedIn ? <Navigate to={baseRoute(WEB_ROUTES.MY_ALBUMS)} replace /> : children

    );
}
