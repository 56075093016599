import React from "react";
import styles from "./styles/index.module.scss";
import { Col, Row } from "react-bootstrap";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import FolderCard from "../../Components/Cards/FolderCard";
import { WEB_ROUTES } from "../../constants";
import { useNavigate } from "react-router-dom";

const SHARED_ALBUM_SCREEN = () => {


  const navigate = useNavigate();

  const handleNewFolder = () => {
    alert('New Folder...')
  }

  const handleOpenFolder = (folderName) => {
    // alert('Open Folder' + folderName);
    navigate('/' + WEB_ROUTES.OPEN_FOLDER, { replace: true, state: { folderName: folderName } }); // send data on navigated screen 
  }

  return (
    <div className={styles.Dashboard}>


      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>

        </div>
      </div>

      <div className="d-flex flex-column flex-md-row align-items-center justify-content-between mb-4">

        <Breadcrumb currentFolder="Folders" />

        <div className="d-flex flex-column flex-md-row">
          <div className={styles.blackBtn} onClick={handleNewFolder}>
            <img src="images/icons/new_folder_icon.svg" className="me-1" alt="" />
            New Folder
          </div>
        </div>
      </div>

      <Row className={` mt-2 mb-2`}  >
        <Col lg={4} md={6} sm={12} className="p-3">
          <FolderCard folderName="Memories" handleOpenFolder={handleOpenFolder} />
        </Col>

        <Col lg={4} md={6} sm={12} className="p-3">
          <FolderCard folderName="Friends" handleOpenFolder={handleOpenFolder} />
        </Col>

        <Col lg={4} md={6} sm={12} className="p-3">
          <FolderCard folderName="Family" handleOpenFolder={handleOpenFolder} />
        </Col>
        <Col lg={4} md={6} sm={12} className="p-3">
          <FolderCard folderName="Memories" handleOpenFolder={handleOpenFolder} />
        </Col>

        <Col lg={4} md={6} sm={12} className="p-3">
          <FolderCard folderName="Friends" handleOpenFolder={handleOpenFolder} />
        </Col>

        <Col lg={4} md={6} sm={12} className="p-3">
          <FolderCard folderName="Family" handleOpenFolder={handleOpenFolder} />
        </Col>
      </Row>
    </div>
  );
};

export default SHARED_ALBUM_SCREEN;
