import React from "react";

import styles from "./styles/Layout.module.scss"
import Inbox from "./Inbox";
import ChatThreads from "./ChatThreads";

export default function MobileLayout({ user = '', setUser }) {
    return (
        <div className={styles.Layout}>
            {user ?
                <Inbox
                    isMobile={true}
                    setUser={setUser}
                /> :
                <ChatThreads
                    setUser={setUser}
                />
            }

        </div>
    )
}