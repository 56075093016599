import React from 'react'
import styles from './index.module.scss';

import { Form } from 'react-bootstrap'
import { IoIosArrowBack } from "react-icons/io";

export const ShadowContainer = () => {
    return (
        <div className={styles.ShadowContainer}>
        </div>
    )
}

export const BackButton = ({
    isRelative = false,
    handleBackButton = () => { }
}) => {
    return (
        <div
            className={`${styles.BackButton} ${isRelative ? styles.relative : ''}`}
            onClick={handleBackButton}
        >
            <IoIosArrowBack />
        </div>
    )
}


export const Input = ({
    type = 'text',
    classes = 'mt-1 mb-2 ',
    style = {},
    size = 'lg',
    placeholder = '',
    onChange = () => { },
    disabled = false,
    fontClass = '',
    value = '',
    as = 'input',
    name = '',
    rows = '1'
}) => {
    return (
        <Form.Control
            style={style}
            size={size}
            className={` ${styles.Input} ${fontClass}  ${classes}`}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            type={type}
            rows={rows}
            name={name}
            as={as}
            disabled={disabled}
        />
    )
}



export const Description = ({
    text = '',
    classes = '',
    onClick = () => { }

}) => {
    return (
        <p
            className={`${styles.Description} ${classes} fs-7`}
            onClick={onClick}
        >{text}</p>
    )
}

export const CardDescription = ({
    text = '',
    classes = '',

}) => {
    return (
        <p
            className={`${styles.Description} ${classes} fs-10`}
        >
            {text}</p>
    )
}


export const Title = ({
    text = '',
    classes = ''

}) => {
    return (
        <h1
            className={`${styles.Header} ${classes}`}
        >{text}</h1>
    )
}

export const SubTitle = ({
    text = '',
    classes = ''

}) => {
    return (
        <h2
            className={`${styles.SubTitle} ${classes}`}
        >{text}</h2>
    )
}
export const CardTitle = ({
    text = '',
    classes = ''

}) => {
    return (
        <h6
            className={`${styles.CardTitle} ${classes} `}
        >{text}</h6>
    )
}
export const CardMatchTitle = ({
    text = '',
    classes = ''

}) => {
    return (
        <h6
            className={`${styles.CardMatchTitle} ${classes} mt-1 mb-1`}
        >{text}</h6>
    )
}

export const Button = ({
    text = '',
    classes = '',
    style = {},
    onClick = () => { }

}) => {
    return (
        <button
            className={`${styles.Button} ${classes}`}
            style={style}
            onClick={onClick}
        >
            {text}
        </button>
    )
}

export const TextDivider = ({
    text = '',
    classes = ''
}) => {
    return (
        <div className={`${styles.TextDivider} ${classes}`}>{text}</div>
    )
}

export const UnorderedList = ({
    lists = [],
    classes = '',
    item_classes = ''
}) => {
    return (
        <ul className={`${styles.UL} ${classes} fs-7`}>
            {lists.map((item, index) => <li key={index} className={`${item_classes} mt-2 mb-2 fs-7`}>{item}</li>)}
        </ul>
    )
}

export const Checkbox = ({
    label = '',
    classes = ''
}) => {
    return (
        <Form.Check

            type='checkbox'
            className={`${styles.Checkbox} ${classes}`}
            label={label}
        />
    )
}

export const InputOTP = ({ props }) => {
    return (
        <Form.Control
            {...props}
            type='text'
            style={{ padding: '25px !important', fontSize: '20px !important' }}
            className={` ${styles.InputOTP}`}
        // size='lg'
        />
    )
}

export const LiveBadge = ({
    text = 'Live',
    classes = ''
}) => {
    return (
        <span className={`${styles.LiveBadge} ${classes}`}>{text}</span>
    )
}

export const Score = ({
    text = '',
    classes = ''
}) => {
    return (
        <span className={`${styles.Score} ${classes}`}>
            {text}
        </span>
    )
}

