import React, { useMemo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';


import './_global.scss';

import { WEB_ROUTES } from "./constants";
import { baseRoute } from "./Helper";
import PublicRoute from "./Components/Routes/PublicRoute";

import ProtectedLayout from "./Components/Layouts/ProtectedLayout";
import ProtectedRoute from "./Components/Routes/ProtectedRoute";

import Account from "./Pages/Account";
import MY_ALBUM_SCREEN from "./Pages/MyAlbums";
import SHARED_ALBUMS from "./Pages/Archive";
import ARCHIVE_SCREEN from "./Pages/Archive/Archive";
import Messages from "./Pages/Messages";
import RECYCLE_SCREEN from "./Pages/Recycle/Recycle";
import SETTING_SCREEN from "./Pages/Setting/Setting";
import PROFILE_SCREEN from "./Pages/Profile/Profile";
import PASSWORD_SCREEN from "./Pages/Password/Password";
import HELP_SCREEN from "./Pages/Help/Help";
import UPLOADS_PHOTOS_SCREEN from "./Pages/UploadPhotos/UploadPhotos";
import OPEN_FOLDER_SCREEN from "./Pages/OpenFolder/OpenFolder";
import ForgotPassword from "./Pages/Account/ForgotPassword";


function App() {

  // todo: menu
  const ROUTES = useMemo(() => {
    return [
      <Route exact path={baseRoute(WEB_ROUTES.ACCOUNT)} element={
        <PublicRoute >
          <Account />
        </PublicRoute>
      } />,
      <Route exact path={baseRoute(WEB_ROUTES.MY_ALBUMS)} element={
        <ProtectedRoute>
          <ProtectedLayout isNavBar={true} selectedRoute={0} title="My Album">
            <MY_ALBUM_SCREEN />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route exact path={baseRoute(WEB_ROUTES.UPLOADS_PHOTOS)} element={
        <ProtectedRoute>
          <ProtectedLayout isNavBar={true} selectedRoute={0} title="Upload Photos" isSearchBar={false}>
            <UPLOADS_PHOTOS_SCREEN />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route exact path={baseRoute(WEB_ROUTES.OPEN_FOLDER)} element={
        <ProtectedRoute>
          <ProtectedLayout isNavBar={true} selectedRoute={0} title="My Album">
            <OPEN_FOLDER_SCREEN />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route exact path={baseRoute(WEB_ROUTES.SHARED_ALBUMS)} element={
        <ProtectedRoute>
          <ProtectedLayout selectedRoute={1} isNavBar={true} title="Shared Albums"  >
            <SHARED_ALBUMS />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route exact path={baseRoute(WEB_ROUTES.CHAT_ADMIN)} element={
        <ProtectedRoute>
          <ProtectedLayout selectedRoute={2} isNavBar={true} title="Chat with Admin" isSearchBar={false}>
            <Messages />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route exact path={baseRoute(WEB_ROUTES.ARCHIVE)} element={
        <ProtectedRoute>
          <ProtectedLayout selectedRoute={3} title="Archive" isNavBar={true}>
            <ARCHIVE_SCREEN />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route exact path={baseRoute(WEB_ROUTES.RECYCLE)} element={
        <ProtectedRoute>
          <ProtectedLayout selectedRoute={4} isNavBar={true} title="Recycle bin" >
            <RECYCLE_SCREEN />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,

      <Route exact path={baseRoute(WEB_ROUTES.SETTING)} element={<Navigate to={baseRoute(WEB_ROUTES.PROFILE)} />} />,

      <Route exact path={baseRoute(WEB_ROUTES.PROFILE)} element={
        <ProtectedRoute>
          <ProtectedLayout selectedRoute={6} isNavBar={true} title='Account Settings' description='Change your profile and account settings' isSearchBar={false} isHomeButton={true}>
            <PROFILE_SCREEN />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route exact path={baseRoute(WEB_ROUTES.PASSWORD)} element={
        <ProtectedRoute>
          <ProtectedLayout selectedRoute={7} isNavBar={true} title="Change Password" isSearchBar={false} isHomeButton={true}>
            <PASSWORD_SCREEN />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,

      <Route exact path={baseRoute(WEB_ROUTES.HELP)} element={
        <ProtectedRoute>
          <ProtectedLayout selectedRoute={8} isNavBar={true} title="Help & Support" isSearchBar={false} isHomeButton={true}>
            <HELP_SCREEN />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,

    ]

  }, [])

  return (
    <Router>
      <Routes>
        {ROUTES.map((item) => item)}
        <Route path={baseRoute('*')} element={<Navigate to={baseRoute(WEB_ROUTES.ACCOUNT)} />} />
      </Routes >


    </Router >
  );
}

export default App;
